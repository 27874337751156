import * as React from 'react'
import { Link } from 'gatsby'
import { css, SerializedStyles } from '@emotion/react'

import { useLikeMap } from '../../modules/useLikeMap'
import CssProperties from '../../mixins/cssProperties'

import HomeIcon from '../atoms/icons/HomeIcon'
import LikeIcon from '../atoms/icons/LikeIcon'
import NotLikeIcon from '../atoms/icons/NotLikeIcon'
import TwitterIcon from '../atoms/icons/TwitterIcon'
import HatenaBookmarkIcon from '../atoms/icons/HatenaBookmarkIcon'

interface IProps {
  createNumber: string
  title: string
}

export default ({ createNumber, title }: IProps): React.ReactElement => {
  // baseURL
  const articleURL = `https://blog.toru-takagi.dev/article/${createNumber}`

  // Twitter URL
  const twitterPostURL = `https://twitter.com/intent/tweet?text=${title} - Aspiring%20Engineer&url=${articleURL}&via=TTrpbm`

  // hatena URL
  const hatenaPostURL = `https://b.hatena.ne.jp/entry/${articleURL}`

  // お気に入りの記事情報を格納
  const { likeMap, handleClickLike } = useLikeMap()

  const isLike = React.useMemo((): boolean => {
    if (!likeMap || likeMap?.get === undefined) return false
    if (!createNumber) return false
    const likeObj = likeMap.get(createNumber)
    return likeObj !== undefined
  }, [likeMap])

  const menuBarStyle: SerializedStyles = css({
    backgroundColor: CssProperties.colors.accentColor,
    height: 'auto',
    '> div': {
      margin: '0 auto',
      padding: '5px',
      display: 'flex',
      justifyContent: 'space-around',
      width: '980px',
      color: CssProperties.colors.white,
      textAlign: 'center',
      fontWeight: 'bold',
      lineHeight: '1.5rem',
      [CssProperties.mediaQuery.isPc]: {
        width: '100%',
      },
      a: {
        color: CssProperties.colors.white,
      },
      '.menu-bar-item': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'auto',
        cursor: 'pointer',
        [CssProperties.mediaQuery.isSp]: {
          fontSize: '1rem',
        },
        svg: {
          height: '25px',
          fill: CssProperties.colors.white,
          [CssProperties.mediaQuery.isSp]: {
            height: '17px',
          },
        },
      },
    },
  })

  // 記事詳細ページのメニューバーを描画
  return (
    <div css={menuBarStyle}>
      <div id='menu-bar'>
        <Link to='/'>
          <div className='menu-bar-item'>
            <HomeIcon />
            <span>Home</span>
          </div>
        </Link>
        <div
          className='menu-bar-item'
          data-like={isLike}
          data-title={title}
          data-create-number={createNumber}
          onClick={handleClickLike}
        >
          {isLike ? <LikeIcon /> : <NotLikeIcon />}
          <span>Like</span>
        </div>
        <a href={twitterPostURL} target='_blank'>
          <div className='menu-bar-item'>
            <TwitterIcon />
            <span>Twitter</span>
          </div>
        </a>
        <a href={hatenaPostURL} target='_blank'>
          <div className='menu-bar-item'>
            <HatenaBookmarkIcon />
            <span>Hatena</span>
          </div>
        </a>
      </div>
    </div>
  )
}
