import * as React from 'react'

export default (): React.ReactElement => {
  return (
    <svg viewBox='0 0 38 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.793119 32V0.71875H11.7502C15.5457 0.71875 18.4246 1.44922 20.3869 2.91016C22.3491 4.35677 23.3302 6.48372 23.3302 9.29102C23.3302 10.8236 22.9363 12.1771 22.1486 13.3516C21.3608 14.5117 20.2651 15.3639 18.8615 15.9082C20.4656 16.3092 21.7261 17.1185 22.6427 18.3359C23.5737 19.5534 24.0392 21.043 24.0392 22.8047C24.0392 25.8125 23.0796 28.0898 21.1603 29.6367C19.241 31.1836 16.5054 31.9714 12.9533 32H0.793119ZM7.23843 18.3789V26.8223H12.7599C14.2781 26.8223 15.4598 26.4642 16.3048 25.748C17.1642 25.0176 17.5939 24.015 17.5939 22.7402C17.5939 19.8757 16.1115 18.4219 13.1466 18.3789H7.23843ZM7.23843 13.8242H12.008C15.2593 13.7669 16.8849 12.4707 16.8849 9.93555C16.8849 8.51758 16.4696 7.50065 15.6388 6.88477C14.8224 6.25456 13.5262 5.93945 11.7502 5.93945H7.23843V13.8242ZM36.5629 22.3965H31.4066L30.6762 0.71875H37.2934L36.5629 22.3965ZM33.9848 25.791C35.0303 25.791 35.8682 26.099 36.4984 26.7148C37.143 27.3307 37.4652 28.1185 37.4652 29.0781C37.4652 30.0234 37.143 30.804 36.4984 31.4199C35.8682 32.0358 35.0303 32.3438 33.9848 32.3438C32.9535 32.3438 32.1156 32.0358 31.4711 31.4199C30.8409 30.804 30.5258 30.0234 30.5258 29.0781C30.5258 28.1328 30.8409 27.3522 31.4711 26.7363C32.1156 26.1061 32.9535 25.791 33.9848 25.791Z'
        fill='#F5F7FA'
      />
    </svg>
  )
}
